<template>

    <v-parallax
      class="blur  fill-height"
          :height="$vuetify.breakpoint.smAndDown ? 700 : 850"
          src="../assets/galerie/Salle de conference/couverture.jpg"
          dark
        >
          <v-container fill-height>
            <v-row 
            class="text-center"
              align="center"
              justify="center">
              <v-col
                class="text-center"
                cols="12"
                md="4"
                sm="4"
                xs="1"
              >
              <v-img
                class="animate__animated animate__zoomIn"
                :src="require('@/assets/EFAM.png')"
                contain
              />
              </v-col>
            </v-row>
            
            <div class="text-center mx-auto">
                <h1 
                  :class="[$vuetify.breakpoint.smAndDown ? 'display-2': 'display-4']"
                  class="font-weight-black animate__animated animate__slideInUp">
                      Bienvenue à EFAM</h1> 
                <h2 
                  :class="[$vuetify.breakpoint.smAndDown ? 'display-1': 'display-3']"
                  class="font-weight-black animate__animated animate__slideInUp mt-5">
                  ECOLE DE FORMATION A L'APPROCHE DES METIERS</h2> <br>
                <h2 
                  :class="[$vuetify.breakpoint.smAndDown ? '': 'display-2']"
                  class="font-weight-black animate__animated animate__slideInUp">
                  L'expert de la formation certifiante intensive et accélérée</h2>
                <!-- <h2>Agréée par le Ministère de la Formation et de l’Enseignement Professionnels</h2> -->
              </div>
            <v-row 
              align="center"
              justify="center">
                <v-col
                  class="text-center mx-auto"
                  cols="12"
                  md="3"
                  sm="2"
                  xs="1"
                >
                <v-btn
                    class="align-self-end"
                    elevation="5"
                    fab
                    x-large
                    @click="$vuetify.goTo('#nos-valeurs')"
                  >
                    <v-icon>fas fa-chevron-down</v-icon>
                  </v-btn>
                </v-col>
               </v-row>
          </v-container>
        </v-parallax>
    
</template>

<script>
  export default {
    name: 'Parallax',
  }

</script>